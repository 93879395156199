import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../shared/firebase";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Slider,
  Alert,
  Badge,
  Avatar,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  Mic as MicIcon,
  Stop as StopIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../lib/theme";
import { formatDate } from "../../shared/formatDate";
import RefreshLink from "../../shared/RefreshLink";
import ConfirmDialog from "../../shared/ConfirmDialog";

const TrackItem = ({
  track,
  isPlaying,
  playingTrackId,
  handlePlayPause,
  currentTime,
  duration,
  handleSeek,
  user,
  userId,
  sharedWith,
  isRecording,
  recordingTrackId,
  selectedTrackId,
  stopRecording,
  startRecording,
  editingTrackId,
  editingTrackTitle,
  handleEditTrack,
  handleSaveTrackTitle,
  handleCancelEdit,
  setEditingTrackTitle,
  handleDeleteTrack,
  albumId,
  showTrackControls = true,
  remainingTime,
  handleDeleteSegment,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordedByUser, setRecordedByUser] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [segmentUsers, setSegmentUsers] = useState({});
  const canEditTrack =
    user && (user.uid === userId || sharedWith.includes(user.uid));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [segmentToDelete, setSegmentToDelete] = useState(null);

  useEffect(() => {
    const fetchRecordedByUser = async () => {
      if (track.audioSegments && track.audioSegments.length > 0) {
        const recordedByUid =
          track.audioSegments[track.audioSegments.length - 1].recordedBy;
        if (recordedByUid) {
          try {
            const userDoc = await getDoc(doc(db, "users", recordedByUid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setRecordedByUser(userData);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      }
    };

    fetchRecordedByUser();
  }, [track.audioSegments]);

  useEffect(() => {
    const fetchSegmentUsers = async () => {
      if (track.audioSegments && track.audioSegments.length > 0) {
        const userPromises = track.audioSegments.map(async (segment) => {
          if (segment.recordedBy) {
            try {
              const userDoc = await getDoc(
                doc(db, "users", segment.recordedBy)
              );
              if (userDoc.exists()) {
                return { [segment.id]: userDoc.data() };
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
            }
          }
          return { [segment.id]: null };
        });

        const users = await Promise.all(userPromises);
        setSegmentUsers(Object.assign({}, ...users));
      }
    };

    fetchSegmentUsers();
  }, [track.audioSegments]);

  useEffect(() => {
    if (
      isPlaying &&
      track.audioSegments &&
      track.audioSegments.some((segment) => segment.id === playingTrackId)
    ) {
      setExpanded(true);
    }
  }, [isPlaying, playingTrackId, track.audioSegments]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60) || 0;
    const seconds = Math.floor(time % 60) || 0;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const isCurrentTrackSelected =
    selectedTrackId ===
    track.audioSegments?.[track.audioSegments.length - 1]?.id;

  const isCurrentTrackPlaying = isPlaying && isCurrentTrackSelected;

  const isTrackPlaying =
    isPlaying &&
    track.audioSegments &&
    track.audioSegments.some((segment) => segment.id === playingTrackId);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDeleteSegmentClick = (trackId, segmentId) => {
    setSegmentToDelete({ trackId, segmentId });
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (segmentToDelete) {
      handleDeleteSegment(segmentToDelete.trackId, segmentToDelete.segmentId);
    }
    setDeleteDialogOpen(false);
    setSegmentToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSegmentToDelete(null);
  };

  return (
    <li style={{ marginBottom: "16px", listStyleType: "none" }}>
      {isRecording && recordingTrackId === track.id && remainingTime <= 120 && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          Recording will end in {remainingTime} seconds
        </Alert>
      )}
      <Box
        display="flex"
        alignItems="center"
        sx={{
          padding: "8px",
          borderRadius: "4px",
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        {/* Play/Pause button */}
        <Box mr={1}>
          {track.audioSegments && track.audioSegments.length > 0 && (
            <IconButton
              onClick={() => handlePlayPause(track, 0)} // Start playing from the first segment
              sx={{ color: colors.white }}
            >
              {isTrackPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          )}
        </Box>

        {/* Track title and editing */}
        <Box flexGrow={1}>
          {editingTrackId === track.id ? (
            <Box display="flex" alignItems="center">
              <TextField
                value={editingTrackTitle}
                onChange={(e) => setEditingTrackTitle(e.target.value)}
                size="small"
                sx={{ mr: 1, minWidth: 350 }}
              />
              <IconButton
                onClick={handleSaveTrackTitle}
                sx={{ color: colors.white }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={handleCancelEdit}
                sx={{ color: colors.white }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Typography sx={{ color: colors.white }}>
              <RefreshLink to={`/album/${albumId}/track/${track.id}`}>
                {track.title}
              </RefreshLink>
              {isRecording && recordingTrackId === track.id && (
                <CircleIcon
                  sx={{ color: "red", ml: 1, width: 12, height: 12 }}
                />
              )}
            </Typography>
          )}
          {track.updatedAt && (
            <Typography
              variant="caption"
              sx={{
                color: colors.lightYellow,
                display: "flex",
                alignItems: "center",
              }}
            >
              {track.audioSegments && track.audioSegments.length > 0 && (
                <>
                  Recorded {formatDate(track.updatedAt)}
                  {recordedByUser?.profilePhotoURL && (
                    <Avatar
                      src={recordedByUser.profilePhotoURL}
                      alt={
                        recordedByUser?.firstName ||
                        recordedByUser?.email ||
                        "Unknown"
                      }
                      sx={{ width: 32, height: 32, ml: 1, mr: 0.5 }}
                    />
                  )}
                  <div style={{ marginLeft: "4px", marginRight: "4px" }}>By</div>
                  <RefreshLink
                    to={`/user/${recordedByUser?.userId || ""}`}
                    sx={{ fontWeight: "bold", mx: "4px" }}
                  >
                    {`${
                      recordedByUser?.firstName ||
                      recordedByUser?.email ||
                      "Unknown"
                    }`}
                  </RefreshLink>
                </>
              )}
            </Typography>
          )}
        </Box>

        {/* Add expand/collapse button with badge */}
        {track.audioSegments && track.audioSegments.length > 1 && (
          <Badge
            badgeContent={track.audioSegments.length}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                right: 3,
                top: 3,
                border: `2px solid ${colors.darkGrey}`,
                padding: "0 4px",
              },
            }}
          >
            <IconButton onClick={toggleExpand} sx={{ color: colors.white }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Badge>
        )}

        {/* Action buttons */}
        {canEditTrack && (
          <Box>
            {showTrackControls && (
              <>
                <IconButton
                  onClick={() =>
                    isRecording ? stopRecording() : startRecording(track.id)
                  }
                  sx={{ color: colors.white }}
                >
                  {isRecording && recordingTrackId === track.id ? (
                    <StopIcon />
                  ) : (
                    <MicIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={handleMenuOpen}
                  sx={{ color: colors.white }}
                >
                  <MoreVertIcon />
                </IconButton>
              </>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleEditTrack(track.id, track.title);
                  handleMenuClose();
                }}
              >
                <EditIcon sx={{ mr: 1 }} />
                Rename
              </MenuItem>
              {user &&
                track.audioSegments &&
                track.audioSegments.length === 1 &&
                track.audioSegments.some(
                  (segment) => segment.recordedBy === user.uid
                ) && (
                  <MenuItem
                    onClick={() => {
                      handleDeleteSegmentClick(
                        track.id,
                        track.audioSegments[0].id
                      );
                      handleMenuClose();
                    }}
                  >
                    <DeleteIcon sx={{ mr: 1 }} />
                    Delete My Recording
                  </MenuItem>
                )}
              <MenuItem
                onClick={() => {
                  handleDeleteTrack(track.id);
                  handleMenuClose();
                }}
              >
                <DeleteIcon sx={{ mr: 1 }} />
                Delete Track
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>

      {/* Audio segments list and slider */}
      {(expanded ||
        (isPlaying &&
          track.audioSegments &&
          track.audioSegments.some(
            (segment) => segment.id === playingTrackId
          ))) &&
        track.audioSegments &&
        track.audioSegments.length > 1 ? (
          <Box sx={{ ml: 4, mt: 1 }}>
            {track.audioSegments.map((segment, index) => (
              <Box key={segment.id} sx={{ mb: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => handlePlayPause(track, index)} // Play specific segment
                    sx={{ color: colors.white, mr: 1 }}
                  >
                    {isPlaying && playingTrackId === segment.id ? (
                      <PauseIcon />
                    ) : (
                      <PlayArrowIcon />
                    )}
                  </IconButton>
                  <Box
                    sx={{
                      fontSize: "0.9rem",
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {segmentUsers[segment.id] ? (
                      <>
                        {segmentUsers[segment.id].profilePhotoURL && (
                          <Avatar
                            src={segmentUsers[segment.id].profilePhotoURL}
                            alt={
                              segmentUsers[segment.id].firstName ||
                              segmentUsers[segment.id].email ||
                              "Unknown"
                            }
                            sx={{ width: 32, height: 32, mr: 0.5 }}
                          />
                        )}
                        <div style={{ marginRight: "4px" }}>By</div>
                        <RefreshLink
                          to={`/user/${segmentUsers[segment.id].userId}`}
                          sx={{ fontWeight: "bold", mx: "4px" }}
                        >
                          {segmentUsers[segment.id].firstName ||
                            segmentUsers[segment.id].email ||
                            "Unknown"}
                        </RefreshLink>
                        <div style={{ marginLeft: "4px" }}>
                          on {formatDate(segment.updatedAt)}
                        </div>
                      </>
                    ) : (
                      `Recording ${index + 1}: ${formatDate(segment.updatedAt)}`
                    )}
                  </Box>
                  {user && user.uid === segment.recordedBy && (
                    <IconButton
                      onClick={() =>
                        handleDeleteSegmentClick(track.id, segment.id)
                      }
                      sx={{ color: colors.white }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {isPlaying && playingTrackId === segment.id && (
                  <Box sx={{ ml: 6, mt: 1 }}>
                    <Slider
                      value={currentTime}
                      min={0}
                      max={duration}
                      onChange={(e, value) => handleSeek(value)}
                      aria-labelledby="audio-slider"
                      sx={{ color: colors.gold }}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          // Add this block for single segment tracks
          isPlaying &&
          track.audioSegments &&
          track.audioSegments.length === 1 &&
          track.audioSegments[0].id === playingTrackId && (
            <Box sx={{ ml: 6, mt: 1 }}>
              <Slider
                value={currentTime}
                min={0}
                max={duration}
                onChange={(e, value) => handleSeek(value)}
                aria-labelledby="audio-slider"
                sx={{ color: colors.gold }}
              />
            </Box>
          )
        )}

      {/* ... existing code for current track player ... */}

      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Delete Your Recording"
        content="Are you sure you want to delete this recording? This action cannot be undone."
        confirmText="Delete"
      />
    </li>
  );
};

export default TrackItem;
